import commissaireService from '@/services/http/commissaire.service.js'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async createCommissaire({ rootState }, data) {
            const res = await commissaireService.createCommissaire(data)
            return res
        },
        async findCommissaire({ rootState }, data) {
            const res = await commissaireService.findCommissaire(data)
            return res
        },
        async getCommissaire({ rootState }, data) {
            const res = await commissaireService.getCommissaire(data)
            return res
        },
        async updateCommissaire({ rootState }, data) {
            const res = await commissaireService.updateCommissaire(data)
            return res
        },

        async createAgentPolice({ rootState }, data) {
            const res = await commissaireService.createAgentPolice(data)
            return res
        },

        // async findEtablissement({ rootState }, data) {
        //     const res = await etablissementsService.findEtablissement(data)
        //     return res
        // },
        // async verifyEtablissementEmail({ rootState }, data) {
        //     const res = await etablissementsService.verifyEtablissementEmail(data)
        //     return res
        // },
        // async validateInscriptionEtablissement({ rootState }, data) {
        //     const res = await etablissementsService.validateInscriptionEtablissement(data)
        //     return res
        // },
        // async rejectInscriptionEtablissement({ rootState }, data) {
        //     const res = await etablissementsService.rejectInscriptionEtablissement(data)
        //     return res
        // },
        // async updateEtablissement({ rootState }, data) {
        //     const res = await etablissementsService.updateEtablissement(data)
        //     return res
        // },
        // async createAgent({ rootState }, data) {
        //     const res = await etablissementsService.createAgent(data)
        //     return res
        // },
        // async getAgent({ rootState }, data) {
        //     const res = await etablissementsService.getAgent(data)
        //     return res
        // },
        // async archiverAgent({ rootState }, data) {
        //     const res = await etablissementsService.archiverAgent(data)
        //     return res
        // },
    },
}