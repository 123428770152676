import { BaseService } from './base.service'

export class CommissaireService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  // eslint-disable-next-line no-unused-vars
  createCommissaire(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('admins/register', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  findCommissaire(data) {
    const params = {}
    if (data && data.options) {
      params.options = data.options
    }
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/admins/list', {
          params,
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getCommissaire(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/admins/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateCommissaire({ id, data }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/admins/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // agent de police
  createAgentPolice(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('admins/register', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new CommissaireService()
